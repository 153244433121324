<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="danger" size="small" :disabled="!btn.delete" @click="doDelete()"><i class="el-icon-delete"></i> 删除</el-button>
        <el-button class="vd_export" size="small" type="info" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
      </div>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="`modc_id`"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @select="selectRows"
        @selection-change="handleSelectionChange"
        @select-all="selectRows"
        @row-dblclick="dbClickJp"
        @getTableData="getModcsList"
      >
        <template v-slot:status="scope">
          <div style="text-align: center">
            <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">
              {{ helper.getStatusName(scope.row.status).name }}
            </el-tag>
          </div>
        </template>
      </search-table>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { modcAPI } from '@api/modules/modc';
import { suppAPI } from '@api/modules/supp';
import { stffAPI } from '@/api/modules/staff';
import { deptAPI } from '@api/modules/department';
import helper from '@assets/js/helper.js';
import pubPagination from '@/components/common/pubPagination';
import { getDateNoTime } from '@assets/js/dateUtils';
import SearchTable from '@/components/table/SearchTableV2.vue';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'ModcList',
  components: {
    SearchTable,
    pubPagination
  },
  data() {
    return {
      tableProperties: [
        this.$store.state.selection,
        this.$store.state.index,
        { prop: 'modc_no', itemType: 'input', label: '变更单据号', input: false },
        { prop: 'scon_no', itemType: 'input', label: '销售合同号', input: true },
        { prop: 'modc_date', itemType: 'date', label: '变更日期', formatter: val => getDateNoTime(val, true) },
        { prop: 'modc_num', itemType: 'input', label: '修改次数', input: false, sortable: false },
        { prop: 'modr_no', itemType: 'input', label: '原合同编号' },
        { prop: 'new_modr_no', itemType: 'input', label: '新合同编号' },
        { prop: 'modr_stff_name', itemType: 'input', label: '采购经办人', sortable: false },
        {
          prop: 'modr_dept_name',
          itemType: 'input',
          label: '采购所属部门',
          options: [],
          input: false,
          sortable: false
        },
        { prop: 'cust_stff_name', itemType: 'input', label: '销售经办人', sortable: false },
        {
          prop: 'cust_dept_name',
          itemType: 'input',
          label: '销售所属部门',
          input: false,
          sortable: false
        },
        {
          prop: 'modc_type',
          itemType: 'select',
          label: '变更类型',
          options: [
            { value: 1, label: '取消订单' },
            { value: 2, label: '订单修改' },
            { value: 3, label: '转厂' },
            // { value: 4, label: '部分转厂' },
            { value: 5, label: '采购价修改' },
            { value: 6, label: '转为库存' }
          ],
          formatter: val => this.formaType(val),
          needOtherColumn: false,
          sortable: false
        },
        this.$store.state.stff_name,
        this.$store.state.create_time,
        this.$store.state.status
      ],
      tableData: [],
      searchForm: {
        modc_no: null,
        modr_no: null,
        supp_id: null,
        mtrb_name: null,
        modr_dept_id: null,
        modr_stff_id: null,
        stff_id: null,
        status: null,
        timeValue: null,
        cust_dept_id: null
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      modcAttrList: [],
      stffUserList: [],
      deptList: [],
      suppOptionList: [],
      statusList: [
        {
          id: 0,
          label: '草拟'
        },
        {
          id: 1,
          label: '在批'
        },
        {
          id: 2,
          label: '生效'
        }
      ]
    };
  },
  mounted() {
    if (this.$route.query.modr_no) {
      this.$refs.multiTable.searchForm.modr_no = this.$route.query.modr_no;
    }
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/modc_add' || from.path === '/modc_edit') {
        this.initData();
      }
    }
  },
  filters: {
    formatDate1(row) {
      return helper.toTimeDay(row);
    }
  },
  methods: {
    initData() {
      this.getModcsList();
      this.getStffUser();
      this.getDept();
      this.getSupps();
    },
    getDept() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    getModcsList() {
      this.loadFlag = true;
      get(modcAPI.getModcs, this.$refs.multiTable.searchForm)
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(() => {});
    },
    // 获取供应商信息
    getSupps() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppOptionList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取人员信息
    getStffUser() {
      get(stffAPI.getAllStffsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.stffUserList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 刷新
    buttonRefresh() {
      this.$refs.multiTable.resetFields();
      this.getModcsList();
    },
    // 查询方法
    getModcsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getModcsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      this.jump('/modc_edit', {
        key: UrlEncode.encode(
          JSON.stringify({
            perm_id: this.$route.query.perm_id,
            form_id: row.modc_id
          })
        )
      });
    },
    // 分页查询
    changePageSearch(val) {
      this.loadFlag = true;
      this.currentPage = val;
      this.getModcsList();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'error', message: '此单据已生效或者正在审批中,无法删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    //客户信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map(item => {
            ids.push(item.modc_id);
          });
          post(modcAPI.deleteModcByIds, { modc_id_list: ids })
            .then(res => {
              let mg = res.data.msg;
              if (res.data.code === 0) {
                let tp = 'success';
                this.$message({ message: mg, type: tp });
                if (ids.length === this.tableData.length) {
                  if (this.currentPage > 1) {
                    this.currentPage = this.currentPage - 1;
                    this.$refs.pubPagination.currentPage = this.currentPage;
                  }
                }
                this.initData();
              } else {
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData();
            });
        })
        .catch(() => {
          let mg = '取消删除！';
          let tp = 'info';
          this.$message({ message: mg, type: tp });
        });
    },
    // 回显变更类型
    formaType(modc_type) {
      switch (modc_type) {
        case 1:
          return '取消订单';
        case 2:
          return '订单修改';
        case 3:
          return '转厂';
        case 5:
          return '采购价修改';
        case 6:
          return '转为库存';
        default:
          return '暂无';
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
